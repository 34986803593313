<template>
    <div v-if="content" :class="CSSClasses" class="content-card-block">
        <div class="main">
            <template v-if="!hideImage">
                <ImageCollage v-if="content.images?.length" :images="content.images" class="visual" />
                <div v-else-if="content.videoPoster && !(content.label === 'event')" class="visual">
                    <Anchor :url="translationFriendlyUrl">
                        <div class="video-poster">
                            <Image v-bind="content.videoPoster" class="image" :colorVariant="imageColorVariant" />
                            <Icon class="video-play" name="video" />
                        </div>
                    </Anchor>
                </div>
                <div v-else-if="content.image" class="visual" :class="content.imageType">
                    <Anchor :url="content.mediaSource?.url ? content.mediaSource.url : translationFriendlyUrl">
                        <Image
                            v-bind="content.image"
                            class="image"
                            :variant="content.imageType || content?.label || ''"
                            :colorVariant="imageColorVariant"
                        />
                    </Anchor>
                </div>
            </template>
            <ContentSummary
                :dateTime="content?.dateTime"
                :label="content?.label"
                :people="content?.mainAuthors || content?.people"
                :url="content?.url"
                :isDescriptionHidden="isDescriptionHidden"
                :mobileHideDescription="isDescriptionHiddenMobile"
                :theme="contentSummaryTheme"
                :isLabelHidden="hideLabel"
                :isPeopleHidden="hidePeople"
                :isDateCardHidden="hideDateCard"
                :attributions="attributions"
                :mediaSource="content?.mediaSource"
                :publisherName="content?.publisherName"
                :publishedAt="content?.publishedAt"
                :showPublicationDate="showPublicationDate"
                :pageLanguage="pageLang"
                :futureEvent="futureEvent"
                class="summary"
            >
                <template #title>
                    <Typography v-if="content?.title" as="div" :variant="summaryHeadingVariant" class="heading">
                        {{ content?.title }}
                    </Typography>
                </template>
                <template #description>
                    <RichTextElements
                        v-if="content?.description"
                        :bodyStyle="summaryDescriptionVariant"
                        :elements="content?.description"
                        class="description"
                    />
                </template>
            </ContentSummary>
        </div>

        <div v-if="$slots.icon" class="icon">
            <slot name="icon"></slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    size: {
        ...sizes,
    },
    orientation: {
        type: String,
        default: 'tall',
        validator: (value) => {
            return ['tall', 'wide'].includes(value);
        },
    },
    related: {
        type: Object,
    },
    overrideImage: {
        type: [Object, Boolean],
    },
    hideImage: {
        type: Boolean,
        default: false,
    },
    hideDescription: {
        type: Boolean,
        default: false,
    },
    hideLabel: {
        type: Boolean,
        default: false,
    },
    hidePeople: {
        type: Boolean,
        default: false,
    },
    hideDateCard: {
        type: Boolean,
        default: false,
    },
    mobileHideDescription: {
        type: Boolean,
        default: false,
    },
    showPublicationDate: {
        type: Boolean,
        default: false,
    },
    isProportionate: {
        type: Boolean,
        default: false,
    },
    futureEvent: {
        type: Boolean,
        default: false,
    },
    context: {
        type: String,
        default: 'block',
        validator: (value) => {
            return ['block', 'inline'].includes(value);
        },
    },
    imageColorVariant: {
        type: String,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
    newSearch: {
        type: Boolean,
        default: false,
    }
});
const pageLang = computed(() => {
    if (props.pageLanguage === '') {
        const { pageLanguage } = usePageDataStore();
        return pageLanguage;
    } else return props.pageLanguage;
});

const isDescriptionHidden =
    props.hideDescription ||
    (props.size === '50' && props.orientation === 'wide' && props.context !== 'inline') ||
    props.size === '25';

const isDescriptionHiddenMobile =
    props.mobileHideDescription ||
    props.size === 'full' ||
    props.size === '33' ||
    (props.size === '50' && props.orientation === 'tall');

const summaryHeadingVariant = computed(() => {
    if (props.size === '100' || props.size === 'full') {
        return 'h2';
    }
    if (props.size === '50' && props.orientation === 'tall') {
        return 'h3';
    }
    if (
        (props.size === '50' && props.orientation === 'wide') ||
        props.size === '33' ||
        props.size === '25' ||
        props.context === 'inline'
    ) {
        return 'h5';
    }
    return 'body-large';
});

const summaryDescriptionVariant = computed(() => {
    if (props.context !== 'inline') {
        switch (props.size) {
            case '100':
            case 'full':
            case '50':
                return 'serif-large';
            case '25':
                return 'serif-small';
        }
    }
    return 'serif-small';
});

const content = computed(() => {
    if (props.newSearch === true) {
        const copy = { ...props.related };
        if (copy.contentType === 'inTheMedia'  && props.size !== '100' && props.size !== 'full') {
            copy.image = {
                url: '/static-images/in-the-media.jpg',
                alt: 'In The Media',
            };
        }
        return copy;
    }

    const contentType = props.related?.contentType;
    if (contentType) {
        const parsedContent = getContentTypeParsers(props.related, props.overrideImage)[contentType]?.();
        if (contentType === 'inTheMedia' && props.size !== '100' && props.size !== 'full') {
            return {
                ...parsedContent,
                image: {
                    url: '/static-images/in-the-media.jpg',
                    alt: 'In The Media',
                },
            };
        }
        return parsedContent;
    }
});

const translationFriendlyUrl = computed(() => {
    return pageLang.value.length ? content?.value?.url + '?lang=' + pageLang.value : content?.value?.url;
});

const hasImage = content.value?.images?.length || content.value?.image || props.overrideImage;

const attributions = computed(() => {
    return useAttribution(props.related?.summary?.syndications);
});

const accentColor = inject('colorContext', 'grey');
const contentSummaryTheme = (props.size === 'full' && hasImage) || accentColor === 'blue' ? 'dark' : 'light';
const CSSClasses = computed(() => {
    return [
        `context-${props.context}`,
        `size-${props.size}`,
        `orientation-${props.orientation}`,
        `background-color-${accentColor}`,
        props.isProportionate && 'is-proportionate',
        (!hasImage || props.hideImage) && 'no-image',
    ];
});
</script>

<style lang="scss" scoped>
.content-card-block {
    position: relative;

    &.context-block {
        @include content-padding($all: true);
        background: color(blanc);

        &.size-full {
            padding: 0;
        }

        &.background-color-white {
            background: color(noir, default, 0.05);
        }

        &.background-color-blue {
            background: color(noir, default, 0.2);
        }
    }

    &.context-inline {
        background: transparent;
    }

    &.context-inline.orientation-tall {
        @include content-padding;
    }

    &.context-inline.orientation-wide {
        @include content-padding($vertical: true);
    }

    .description {
        @include text-truncated($lines: 2);
        color: color(noir, default, 0.6);
        @include margin-tb(0.5rem);
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: 2.1rem;

    .orientation-wide.size-100 &,
    .orientation-wide.size-50 & {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        @include media-query(mobile) {
            align-items: flex-start;
            gap: 1.6rem;
        }
    }

    .orientation-wide.size-100.no-image &,
    .orientation-wide.size-50.no-image & {
        justify-content: flex-end;
    }

    .orientation-wide.size-100 & {
        gap: 3.6rem;
    }

    .orientation-wide.size-50 & {
        gap: 2.4rem;
    }

    .size-33 & {
        @include media-query(mobile) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1.6rem;
        }
    }

    .size-full & {
        position: relative;
    }
}

.visual {
    flex: 0 0 auto;
    width: 100%;

    .image {
        width: inherit;
    }

    .orientation-tall & {
        width: 100%;
    }

    .size-25 & .image {
        aspect-ratio: 264/143;
        &.report,
        &.book,
        &.podcast {
            object-fit: contain;
            margin: 0 auto;
        }
    }

    .size-33 &,
    .size-100.orientation-wide.context-inline & {
        @include media-query(tablet) {
            max-width: 25%;
        }
        @include media-query(phone) {
            max-width: 25%;
        }

        .image {
            aspect-ratio: 376/204;
            &.report,
            &.book,
            &.podcast {
                object-fit: contain;
                margin: 0 auto;
            }

            @include media-query(phone) {
                aspect-ratio: 1/1;
            }
        }
    }

    .size-50 & .image {
        aspect-ratio: 600/324;
        &.report,
        &.book,
        &.podcast {
            object-fit: contain;
            margin: 0 auto;
        }
    }

    .size-50.orientation-wide & {
        max-width: 44%;

        @include media-query(phone) {
            max-width: 25%;
        }

        .image {
            aspect-ratio: 264/143;
            &.report,
            &.book,
            &.podcast {
                object-fit: contain;
                margin: 0 auto;
            }

            @include media-query(phone) {
                aspect-ratio: 1/1;
            }
        }
    }

    .size-50.orientation-wide.context-inline & {
        max-width: 25%;

        .image {
            aspect-ratio: 264/143;
            &.report,
            &.book,
            &.podcast {
                object-fit: contain;
                margin: 0 auto;
            }

            @include media-query(phone) {
                aspect-ratio: 1/1;
            }
        }
    }

    .size-100 & .image {
        aspect-ratio: 824/445;
        width: inherit;
        &.report,
        &.book,
        &.podcast {
            object-fit: contain;
            margin: 0 auto;
        }
    }

    .size-100.orientation-wide & {
        max-width: 66%;
    }

    .size-100.orientation-wide.is-proportionate & {
        height: 32.4rem;

        @include media-query(phone) {
            height: 18.5rem;
        }
    }

    .size-full & {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 40%;
            background: linear-gradient(to top, color(noir, default, 0.8), transparent);
        }
    }

    .is-proportionate & {
        @include media-query(not-phone) {
            flex: 1;
        }
    }

    .size-full & {
        .image {
            max-height: var(--image-max-height);
            width: 100%;
        }
    }

    &.book,
    &.report,
    &.podcast {
        background: color(grey, light);
    }
}

// adjust for covers in lists with other size images

.size-25 .visual {
    &.report,
    &.book {
        aspect-ratio: 264/143;
    }
}
.size-33 .visual,
.size-100.orientation-wide.context-inline .visual {
    &.report,
    &.book {
        aspect-ratio: 376/204;
    }
}

.size-50 .visual {
    &.report,
    &.book {
        aspect-ratio: 600/324;
    }
}

.summary {
    padding-inline-end: 1rem;
    min-width: 0;

    .orientation-wide & {
        max-width: 60rem;
        flex: 1;
    }

    .orientation-wide.no-image & {
        max-width: none;
    }

    .size-33 & {
        @include media-query(tablet) {
            flex: 0 1 auto;
            min-width: 0;
        }
    }

    .size-100.orientation-wide.context-inline & {
        @include media-query(phone) {
            flex: 0 1 auto;
            min-width: 0;
        }
    }

    .size-full & {
        @include content-section;
        @include padding-lr(calc(var(--page-margin) + var(--col-gutter)));
        @include z-index(arbitrary);
        padding-bottom: vertical-space(2);
    }

    .is-proportionate & {
        flex: 1;
    }

    .size-full & {
        position: absolute;
        bottom: 0;
        inset-inline-start: 0;
        padding: 1.8rem 2.6rem;

        @include media-query(tablet-mw) {
            padding: 3.2rem 8.4rem;
        }
    }

    .size-full.no-image & {
        position: relative;
    }

    :deep(.source-label) {
        display: contents;
    }
}

.mobile-results {
    @include media-query(phone) {
        .size-50 {
            :deep(.avatars) {
                display: none;
            }
            :deep(.last-row.credits) {
                display: block;
            }
        }
    }
}

.background-color-blue {
    .context-block {
        background-color: color(noir, default, 0.2);
    }

    .heading {
        color: color(blanc);
    }

    .description {
        color: rgba(var(--color-rgb-blanc-default), 0.6);
    }
}

.icon {
    position: absolute;
    bottom: 1.2rem;
    inset-inline-end: 1.2rem;
}

.video-poster {
    position: relative;

    .video-play {
        position: absolute;
        bottom: 1.2rem;
        inset-inline-start: 1.2rem;
        @include square(4rem);
        color: color(blanc);
    }
}
</style>
